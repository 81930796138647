import React from 'react'
import "./css/navbar.css"
import { ImCross } from "react-icons/im";
import { FaBars } from "react-icons/fa";
function navbar() {
  return (
    <div className='navbar'>
    <div className="logo">
      <img src="https://yourgutmap.co.uk/wp-content/uploads/2023/03/ygm-logo.png" alt="" />

    </div>

    <input id='navbarcheckbox' type='checkbox'/>
   
    <label for="navbarcheckbox" className='navbaricons open'>
      <FaBars style={{ width: '30px', height: '30px' }} />
    </label>
    <label for="navbarcheckbox" className='navbaricons close'>

      <ImCross style={{ width: '30px', height: '30px' }} />
    </label>


    <div className="bar">

      <a href="https://yourgutmap.co.uk/">Home</a>
    
      <div className="aboutlist">
        <a href="https://yourgutmap.co.uk/about/">About</a>


      </div>
      <a href="https://yourgutmap.co.uk/practitioners/">Practitioners</a>
      <a href="https://yourgutmap.co.uk/vitamin-angels/">Vitamin Angels</a>
      <a href="https://academy.yourgutmap.co.uk/">Academy</a>
      <a href='https://yourgutmap.co.uk/shop/'> <button>Shop</button></a>
    </div>
  </div>
  )
}

export default navbar
