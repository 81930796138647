import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
// import "../css/index.css"
import { message } from 'antd';
import Cookies from 'js-cookie';


function Login() {


    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    let navigate = useNavigate();

    const formsub = async (e) => {

        e.preventDefault();

        const hide = message.loading("Action in progress", 0)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("email", email);
        urlencoded.append("password", password);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/login", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result === "user not found") {
                    message.error("Please enter a correct Email Address")
                } else if (result === "Password is incorrect.") {
                    message.error("Please enter a correct Password")
                } else if (result === "Account is disable by Admin") {
                    message.error("Account is disable by Admin")


                } else {

                    message.success("logged in")
                    Cookies.set('email', result.email); // Expires in 7 days
                    Cookies.set('id', result._id); // Expires in 7 days
                    Cookies.set('Name', result.name); // Expires in 7 days
                    Cookies.set('Token', "dskgfsdgfkgsdfkjg35464154845674987dsf@53"); // Expires in 7 days
                    navigate("/")






                }



            })
            .catch(error => console.log('error', error));
        await setTimeout(() => {
            hide(); // Call hide to stop the loading message
           
        }, 2000);
    }



    return (
        <div className='loginmain'>
            <form className='loginform' onSubmit={formsub}>

                <img src='ygm-logo.webp' alt='' width={"100%"} />
                <h4>Login as Practitioner</h4>

                <div className='logindiv'>
                    <label>Email</label>
                    <input value={email} onChange={(e) => setemail(e.target.value)} placeholder='Email' required type='email' name='email' />
                      </div>

                <div className='logindiv'>
                    <label >Password</label>
                    <input value={password} onChange={(e) => setpassword(e.target.value)} placeholder='Password' required type='password' name='password' />
                    </div>


                <div className='logindiv'>

                    <input className='loginbuttonbutton' type='submit' />
                </div>

            </form>
        </div>
    )
}

export default Login
