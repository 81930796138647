import React, { useEffect, useState } from 'react'
import Navbar from '../component/navbar'
import Header from '../component/header'
import Footer from '../component/footer'
import "./css/index.css"
import { Image, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie';

function Index() {

  const loginemail = Cookies.get("email")
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")



  const Navigate = useNavigate()

  const [meeting, setmeeting] = useState([])

  const [catogary, setcatogary] = useState("YOURGUTMAP")


  useEffect(() => {


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions = {
      method: 'GET',
      headers: myHeaders,

      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallevent", requestOptions)
      .then(response => response.json())
      .then(result => setmeeting(result))


  }, [])


  const logout = () => {
    const allCookies = Cookies.get();
    for (const cookieName in allCookies) {
      Cookies.remove(cookieName);
    }

    message.success("Logged out")
    window.location.reload()
  }
  return (
    <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
      {/* <Header/> */}
      <Navbar />
      <div className='mainindex'>

        {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ? (<>
          <h4 className='mainindexh1' style={{ textAlign: 'center', marginBottom: "50px" }}>Login as: {loginname}({loginemail})  <button className='loginbuttonbutton' onClick={logout}>Logout</button></h4>

        </>) : (<>
          <h4 className='mainindexh1' style={{ textAlign: 'center', marginBottom: "50px" }}>Login if you are a Practitioner  <button className='loginbuttonbutton' onClick={() => Navigate("/login")}>Login</button></h4>
        </>)}
        <div className='headingdiv'>
          <h1>Gut Health & Personalised Nutrition Education</h1>
        </div>


        <h1 className='mainindexh1'>Welcome to YourGutMap CPD Academy, our online learning platform for Health Practitioners.</h1>
        <h2 className='mainindexh1' style={{ color: '#4180B7' }}>Here you can register for upcoming Webinars, as well as view relevant Webinars from our library</h2>
        <h2 className='mainindexh1' style={{ color: 'black', fontWeight: 'lighter' }}>Our Webinars are hosted by both our internal team, and a selection of international speakers. Here you can take a deep dive into our tests, published clinical studies, and monthly workshops on gut health.
          By using the below filters, you can identify which Webinars are CPD accredited by your relevant governing body. Alternatively, you can view any of our Webinars and record your learning hours.</h2>



        <div className='catogary'>

          <h3 onClick={() => setcatogary("YOURGUTMAP")}>YOURGUTMAP</h3>
          <h3 onClick={() => setcatogary("BANT")}>BANT</h3>
          <h3 onClick={() => setcatogary("NNA")}>NNA</h3>
          <h3 onClick={() => setcatogary("RICTAT")}>RICTAT</h3>
          <h3 onClick={() => setcatogary("ANP")}>ANP</h3>

        </div>

        {meeting.map((value, index) => {


          const date = new Date(value.Schedule);

          const day = date.getDate();          // 09
          const month = date.getMonth() + 1;   // 09 (Note: months are zero-indexed, so add 1)
          const year = date.getFullYear();     // 2024
          let hours = date.getHours();         // 13
          const minutes = date.getMinutes();

          const monthNames = [
            "January", "February", "March", "April", "May", "June", "July",
            "August", "September", "October", "November", "December"
          ];

          // Determine AM/PM
          const period = hours >= 12 ? 'PM' : 'AM';

          // Convert to 12-hour format
          hours = hours % 12 || 12; // If hour is 0 (midnight), convert to 12

          // Format minutes to always have two digits
          const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;


          const date2 = {
            day: day,
            month: monthNames[month - 1],    // 09 (Note: months are zero-indexed, so add 1)
            hours: hours,                    // Converted to 12-hour format
            minutes: formattedMinutes,       // Format minutes to 2 digits
            period: period
          }
          console.log(value)


          return (<>

            {catogary === value.Category && value.Visibility === "Public" && <>

              <div className='formainpage check' style={{ gap: '10px', marginBottom: '100px' }} >
                <Image src={value.coverphoto} alt='' width={"100%"} />

                <div className='meetingdetails' style={{ width: '100%' }}>

                  <div className='head'>
                    <div class="date-card">
                      <div class="month">{date2.month}</div>
                      <div class="day">{date2.day}</div>
                      <div class="time">{date2.hours}:{date2.minutes} {date2.period} </div>
                    </div>
                    <div >
                      <h2>{value.title}</h2>
                      <p style={{ color: 'gray' }}>By YouGutMap</p>
                    </div>

                  </div>
                  <div>
                    <p style={{ marginLeft: '30px' }}>

                      <span dangerouslySetInnerHTML={{ __html: value.About }} />
                    </p>
                  </div>

                  {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && value.status === true ) ? (<> <button onClick={() => Navigate("/" + value.URL+"?name="+loginname+"&email="+loginemail+"&login=true")}>Watch Now</button>
                  </>) : (<>
                    <button onClick={() => Navigate("/" + value.URL)}>{value.status? (<>Watch Now</>):(<>Register Now</>)}</button>

                  </>)}
                </div>
              </div>
            </>}
          </>)
        })}

      </div>
      <Footer />
    </div>
  )
}

export default Index
